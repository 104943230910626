const modules_med = [
  {
    filename: "tracks/1999/Alexkidd.xm",
    size: "27,000 bytes",
    filters: { love: true },
    text: "composed for alexkidd we founded mandarine then i stupidly leaved to joined condense     but life is full of surprises and i returned to this group later          check out our demos        and say hello on pouet for us",
  },
  {
    filename: "tracks/1999/BRAZIL.XM",
    size: "1.84MB",
    text: "presented at the arf party          the original sample in it came from a cd given by my brasilian cousin but i forgot the name sorry  ",
  },
  {
    filename: "tracks/1999/Batman_love_my_dog!.xm",
    size: "1.39MB",
    filters: { second: true, best: true, love: true },
    text: "second place at the ltp3       at this time i was doing my army time         i wasn t expected this result and i was really happy",
  },
  {
    filename: "tracks/1999/CHEWIE.XM",
    size: "706,276 bytes",
    text: "composed for the mega chevelu chewie from demofr",
  },
  {
    filename: "tracks/1999/Catgroove.xm",
    size: "396,613 bytes",
    text: "track from a traxfr competition",
  },
  {
    filename: "tracks/1999/cornflakes_2.xm",
    size: "11,600 bytes",
    filters: { chiptune: true },
    text: "hidden part of the bame demo by condense",
  },
  {
    filename: "tracks/1999/Couille2.xm",
    size: "5,238 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/1999/Couille_gauche.xm",
    size: "5,700 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/1999/FOLIE.XM",
    size: "1.03MB",
    text: "presented at the track compo at the takeover 1999",
  },
  { filename: "tracks/1999/JAMI.XM", size: "1.33MB" },
  { filename: "tracks/1999/JazzOnTheMoOn!.xm", size: "201,173 bytes" },
  {
    filename: "tracks/1999/Mouche4.xm",
    size: "29,300 bytes",
    filters: { love: true },
    text: "the name of this track came from a joke i did to one of my old friend alcapone          i told him there was a great movie with jean claude van damme called scarabe mouche quatre      he really believed me       what a really fun old memory",
  },
  { filename: "tracks/1999/Ninja.xm", size: "930,586 bytes" },
  {
    filename: "tracks/1999/Piaku.xm",
    size: "275,825 bytes",
    filters: { love: true },
  },
  { filename: "tracks/1999/SOULG.XM", size: "622,208 bytes" },
  { filename: "tracks/1999/STRANGE.XM", size: "1.60MB" },
  { filename: "tracks/1999/Trip2.xm", size: "1.09MB" },
  {
    filename: "tracks/2000/analogik_2000.xm",
    size: "20,000 bytes",
    filters: { best: true, chiptune: true },
  },
  {
    filename: "tracks/2000/analogik_Boite.xm",
    size: "29,641 bytes",
    filters: { chiptune: true },
    text: "that track was a remake from an old mod        i thinked i created a boite a musique melodie       what a joke",
  },
  {
    filename: "tracks/2000/analogik_DARTAGNA.XM",
    size: "47,000 bytes",
    filters: { best: true, chiptune: true },
  },
  {
    filename: "tracks/2000/analogik_Jibe-theme.xm",
    size: "25,400 bytes",
    filters: { chiptune: true },
    text: "well     jibe was the gfx of the demo group condense       we worked together in the same company     and he loved some plant",
  },
  {
    filename: "tracks/2000/analogik_La_crotte_qui_parle.xm",
    size: "11,500 bytes",
    filters: { chiptune: true },
    text: "special greets to arthur",
  },
  {
    filename: "tracks/2000/analogik_Le_pied_gauche.xm",
    size: "13,000 bytes",
    filters: { chiptune: true },
    text: "the main theme of jylam     our linux hero in condense and also great coder in the alambik team",
  },
  {
    filename: "tracks/2000/analogik_Le_poulet_est_cuit.xm",
    size: "14,900 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2000/analogik_Oiseau_qui_pete.xm",
    size: "15,000 bytes",
    filters: { chiptune: true },
    text: "coop with kenet",
  },
  {
    filename: "tracks/2000/analogik_Potager.xm",
    size: "8,169 bytes",
    filters: { chiptune: true },
    text: "sorry for you ears           lol",
  },
  {
    filename: "tracks/2000/analogik_cho7.xm",
    size: "31,700 bytes",
    filters: { best: true },
  },
  {
    filename: "tracks/2000/analogik_coline.xm",
    size: "23,000 bytes",
    filters: { love: true, chiptune: true },
  },
  {
    filename: "tracks/2000/analogik_nia.xm",
    size: "6,666 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2000/analogik_nopseeking_med_and_kenet.xm",
    size: "15,000 bytes",
    filters: { chiptune: true },
    text: "coop with kenet",
  },
  {
    filename: "tracks/2000/analogik_poisson.xm",
    size: "9,673 bytes",
    filters: { chiptune: true },
    text: "presented at the ltp4",
  },
  {
    filename: "tracks/2000/analogik_zougi.mod",
    size: "11,264 bytes",
    filters: { chiptune: true },
    text: "coop with kenet",
  },
  {
    filename: "tracks/2000/condense_BAME1.xm",
    size: "668,000 bytes",
    filters: { best: true },
    text: "i was really proud of this track and this demo       we wanted to do a huge tribute to our favorite demo the arte      moby was my main influence in the demoscene music",
  },
  {
    filename: "tracks/2000/condense_BAME2.xm",
    size: "760,000 bytes",
    filters: { best: true },
    text: "in that one i tryed to do some chick corea style",
  },
  { filename: "tracks/2000/condense_CONCEPT.XM", size: "634,000 bytes" },
  {
    filename: "tracks/2000/condense_Cornflakes2.xm",
    size: "11,600 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2000/echellon_Dream.xm",
    size: "13,506 bytes",
    filters: { chiptune: true },
    text: "maybe you know the dreamcast           maybe you know echellon         maybe you know what i mean",
  },
  { filename: "tracks/2000/jecoute_FEUILLE2.XM", size: "1.22MB" },
  { filename: "tracks/2000/med_canelle.xm", size: "216,487 bytes" },
  { filename: "tracks/2000/med_funkastar.XM", size: "616,000 bytes" },
  {
    filename: "tracks/2000/med_houche_les_galouix.xm",
    size: "244,000 bytes",
    filters: { second: true },
    text: "presented at the ltp four",
  },
  {
    filename: "tracks/2000/med_inMyHouse.xm",
    size: "1.72MB",
    filters: { first: true },
    text: "presented at the buenzli in 2000          win the first place       what a surprised for me",
  },
  {
    filename: "tracks/2000/med_jeanrenedisco.XM",
    size: "1.13MB",
    filters: { third: true },
    text: "third at the vip 2000      i loved to go to this party      and i was always welcome by everybody",
  },
  {
    filename: "tracks/2000/med_lovecow.xm",
    size: "895,800 bytes",
    filters: { second: true },
  },
  { filename: "tracks/2000/med_lovely2.XM", size: "540,000 bytes" },
  {
    filename: "tracks/2000/med_menu.XM",
    size: "20,373 bytes",
    filters: { chiptune: true },
    text: "composed for a bomberman game like",
  },
  {
    filename: "tracks/2000/med_odesay.xm",
    size: "804,000 bytes",
    filters: { second: true, best: true },
    text: "second at the takeover 2000       this track had many life     it end up to be part of a project for alambik       named stan and standwich      ",
  },
  {
    filename: "tracks/2000/med_patate_funky.xm",
    size: "652,000 bytes",
    filters: { third: true, best: true, love: true },
    text: "third at the vip party in 2000",
  },
  {
    filename: "tracks/2000/med_shaggy.XM",
    size: "771,000 bytes",
    text: "this track was made for an aborted project with the group popsy team",
  },
  {
    filename: "tracks/2000/med_soulg.XM",
    size: "622,200 bytes",
    text: "presented at the vip party in 2000",
  },
  {
    filename: "tracks/2000/med_winner.XM",
    size: "16,175 bytes",
    text: "composed for a bomberman game like",
  },
  { filename: "tracks/2000/med_zen2K.XM", size: "699,000 bytes" },
  {
    filename: "tracks/2000/vitaminic_FIGHT22.XM",
    size: "771,000 bytes",
    filters: { best: true },
    text: "this track was selected to be part of the vitaminic cd        back in the day it was dope to be part of this label      it was one of the first online label",
  },
  { filename: "tracks/2001/analogik_2VOICES.XM", size: "63,300 bytes" },
  { filename: "tracks/2001/analogik_2VOICES2.XM", size: "45,555 bytes" },
  {
    filename: "tracks/2001/analogik_3VIL.XM",
    size: "42,800 bytes",
    filters: { best: true, love: true },
  },
  {
    filename: "tracks/2001/analogik_DEEP.XM",
    size: "14,900 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2001/analogik_Rakiz.xm",
    size: "11,933 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2001/condense_mamadisco.xm",
    size: "25,400 bytes",
    filters: { second: true, chiptune: true },
  },
  {
    filename: "tracks/2001/coolphat_ninjaslipdecombat.xm",
    size: "12,000 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2001/jecoute_DADDYSEX.XM",
    size: "1,024,000 bytes",
    filters: { third: true, best: true },
  },
  {
    filename: "tracks/2001/jecoute_DOUBOOM.XM",
    size: "797,200 bytes",
    filters: { third: true },
  },
  {
    filename: "tracks/2001/jecoute_back2lyon.xm",
    size: "718,000 bytes",
    filters: { second: true },
  },
  {
    filename: "tracks/2001/jecoute_daddyremix2001.xm",
    size: "1.14MB",
    text: "released on the label jecoute      this label was really a great ones         the quality of the production and the members were the best for me",
  },
  {
    filename: "tracks/2001/mutantInc_upermarch.xm",
    size: "36,400 bytes",
    filters: { first: true },
    text: "first place at the vip 2001            we did this demo with redribbon and fab during the party place   ",
  },
  { filename: "tracks/2001/razor_raz2.xm", size: "37,741 bytes" },
  {
    filename: "tracks/2001/sucky-boy.xm",
    size: "833,000 bytes",
    filters: { best: true },
    text: "unreleased track in the demoscene",
  },
  { filename: "tracks/2001/tvnet_ADVERTIS.XM", size: "21,500 bytes" },
  { filename: "tracks/2001/tvnet_ART.XM", size: "27,800 bytes" },
  {
    filename: "tracks/2001/tvnet_BUBBLE.XM",
    size: "38,000 bytes",
    filters: { best: true, love: true },
  },
  { filename: "tracks/2001/tvnet_CINEMA.XM", size: "8,200 bytes" },
  { filename: "tracks/2001/tvnet_COMPUTE2.XM", size: "9,900 bytes" },
  { filename: "tracks/2001/tvnet_COMPUTER.XM", size: "27,000 bytes" },
  {
    filename: "tracks/2001/tvnet_CURRY.XM",
    size: "44,000 bytes",
    filters: { love: true },
  },
  {
    filename: "tracks/2001/tvnet_DOT.XM",
    size: "30,900 bytes",
    filters: { love: true },
  },
  {
    filename: "tracks/2001/tvnet_DOT_FLOWER.XM",
    size: "33,420 bytes",
    filters: { love: true },
  },
  {
    filename: "tracks/2001/tvnet_Discolor.xm",
    size: "46,500 bytes",
    filters: { best: true, love: true },
  },
  { filename: "tracks/2001/tvnet_E-COM.XM", size: "21,700 bytes" },
  { filename: "tracks/2001/tvnet_EDUCAT.XM", size: "38,000 bytes" },
  { filename: "tracks/2001/tvnet_Earth.xm", size: "7,777 bytes" },
  { filename: "tracks/2001/tvnet_FASHION.XM", size: "56,400 bytes" },
  { filename: "tracks/2001/tvnet_FINANCE.XM", size: "33,500 bytes" },
  { filename: "tracks/2001/tvnet_FOOD.XM", size: "30,400 bytes" },
  { filename: "tracks/2001/tvnet_GAME.XM", size: "27,900 bytes" },
  {
    filename: "tracks/2001/tvnet_HEALTH.XM",
    size: "24,300 bytes",
    filters: { love: true },
  },
  { filename: "tracks/2001/tvnet_HERE.XM", size: "9,140 bytes" },
  { filename: "tracks/2001/tvnet_HUMAN.XM", size: "33,500 bytes" },
  { filename: "tracks/2001/tvnet_INTERNET.XM", size: "50,000 bytes" },
  { filename: "tracks/2001/tvnet_JACKPOT.XM", size: "66,666 bytes" },
  { filename: "tracks/2001/tvnet_JAIL.XM", size: "26,000 bytes" },
  { filename: "tracks/2001/tvnet_LANDSCAP.XM", size: "23,000 bytes" },
  { filename: "tracks/2001/tvnet_MECHWR.XM", size: "48,300 bytes" },
  { filename: "tracks/2001/tvnet_MUSIC.XM", size: "29,800 bytes" },
  { filename: "tracks/2001/tvnet_NATION.XM", size: "23,000 bytes" },
  { filename: "tracks/2001/tvnet_NEW2.XM", size: "27,511 bytes" },
  { filename: "tracks/2001/tvnet_OFFLINE.XM", size: "48,300 bytes" },
  { filename: "tracks/2001/tvnet_PRESS.XM", size: "50,000 bytes" },
  { filename: "tracks/2001/tvnet_REALESTA.XM", size: "50,000 bytes" },
  {
    filename: "tracks/2001/tvnet_SHOOT.XM",
    size: "50,814 bytes",
    filters: { best: true, love: true },
  },
  {
    filename: "tracks/2001/tvnet_SPIRAL.XM",
    size: "46,400 bytes",
    filters: { best: true, love: true },
  },
  { filename: "tracks/2001/tvnet_SPORT.XM", size: "41,000 bytes" },
  { filename: "tracks/2001/tvnet_SYMBOLS.XM", size: "35,700 bytes" },
  { filename: "tracks/2001/tvnet_TOOLS.XM", size: "50,000 bytes" },
  { filename: "tracks/2001/tvnet_TOYS.XM", size: "24,900 bytes" },
  {
    filename: "tracks/2001/tvnet_TOYS2.XM",
    size: "9,000 bytes",
    filters: { chiptune: true },
  },
  { filename: "tracks/2001/tvnet_TRANSPOR.XM", size: "28,000 bytes" },
  { filename: "tracks/2001/tvnet_TV.XM", size: "29,200 bytes" },
  { filename: "tracks/2001/tvnet_VEGETAL.XM", size: "18,000 bytes" },
  { filename: "tracks/2001/tvnet_WEDDING.XM", size: "7,200 bytes" },
  { filename: "tracks/2001/tvnet_WIND2D.XM", size: "29,517 bytes" },
  { filename: "tracks/2001/tvnet_XMAS.XM", size: "23,000 bytes" },
  { filename: "tracks/2001/tvnet_mirror_cubes.xm", size: "37,000 bytes" },
  {
    filename: "tracks/2001/tvnet_misc.XM",
    size: "32,700 bytes",
    filters: { best: true, love: true },
  },
  {
    filename: "tracks/2001/tvnet_new_school_tiles.xm",
    size: "41,000 bytes",
  },
  { filename: "tracks/2002/condense_KANCE.XM", size: "25,705 bytes" },
  {
    filename: "tracks/2002/lgf_4.MOD",
    size: "15,183 bytes",
    filters: { second: true, chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_5.MOD",
    size: "13,000 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_DEEPTHROAT.XM",
    size: "35,000 bytes",
    filters: { best: true, chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_GUIMOVE.XM",
    size: "11,000 bytes",
    filters: { third: true, love: true, chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_HALLO2K2.XM",
    size: "33,900 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_INTROINV.XM",
    size: "24,500 bytes",
    filters: { best: true, chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_METAL.XM",
    size: "31,000 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_MYTH.XM",
    size: "15,700 bytes",
    filters: { love: true, chiptune: true },
  },
  {
    filename: "tracks/2002/lgf_XMAS.XM",
    size: "20,259 bytes",
    filters: { chiptune: true },
  },
  { filename: "tracks/2002/med_atable.XM", size: "1,020,000 bytes" },
  {
    filename: "tracks/2002/med_supadupafly.mod",
    size: "252,806 bytes",
    filters: { first: true, best: true, love: true },
  },
  { filename: "tracks/2002/med_vodka.XM", size: "5,750 bytes" },
  {
    filename: "tracks/2002/med_where_is_my_poney.xm",
    size: "1,000,000 bytes",
    filters: { first: true },
  },
  {
    filename: "tracks/2002/radio_caca.xm",
    size: "16,105 bytes",
    filters: { chiptune: true },
  },
  {
    filename: "tracks/2002/scenemusic_intro.xm",
    size: "10,430 bytes",
    filters: { chiptune: true },
    text: "composed for the radio scenemusic",
  },
  {
    filename: "tracks/2003/jecoute_Le_chemin_Mauve.xm",
    size: "1.94MB",
    filters: { second: true, best: true, love: true },
    text: "this track was composed for my first wedding",
  },
  {
    filename: "tracks/2003/med_sexy_potatoes_2.xm",
    size: "1.06MB",
    text: "this is a joke track      the idea came from loftstory a french tv reality show       i am not realy proud of that today but back in the day it was really fun    shake out the kenzalol track from redribbon",
  },
  { filename: "tracks/1993/CAPONE.MOD", size: "33,244 bytes" },
  { filename: "tracks/1993/DREAM.MOD", size: "64,984 bytes" },
  {
    filename: "tracks/1993/FLUIDE.mod",
    size: "274,760 bytes",
    filters: { love: true },
  },
  { filename: "tracks/1993/SAHARA.MOD", size: "94,370 bytes" },
  { filename: "tracks/1993/STORM.MOD", size: "73,556 bytes" },
  { filename: "tracks/1993/STORMY.MOD", size: "55,694 bytes" },
  {
    filename: "tracks/1993/YEAH.mod",
    size: "214,004 bytes",
    filters: { love: true },
  },
  { filename: "tracks/1994/FOUND.MOD", size: "98,214 bytes" },
  { filename: "tracks/1994/JAZZY.MOD", size: "99,182 bytes" },
  { filename: "tracks/1994/SING.MOD", size: "97,714 bytes" },
  { filename: "tracks/1995/ALPHA.MOD", size: "118,346 bytes" },
  { filename: "tracks/1995/JUMP.XM", size: "208,819 bytes" },
  { filename: "tracks/1995/MOVE.MOD", size: "118,274 bytes" },
  { filename: "tracks/1995/RAI.MOD", size: "169,520 bytes" },
  { filename: "tracks/1995/RECTO.MOD", size: "210,328 bytes" },
  { filename: "tracks/1995/SEXY.MOD", size: "133,394 bytes" },
  { filename: "tracks/1995/ST2.MOD", size: "98,186 bytes" },
  { filename: "tracks/1996/7GUNS.MOD", size: "394,834 bytes" },
  { filename: "tracks/1996/BOUFFON.MOD", size: "260,004 bytes" },
  { filename: "tracks/1996/BRAZI.MOD", size: "409,462 bytes" },
  { filename: "tracks/1996/BRAZIL.MOD", size: "334,718 bytes" },
  {
    filename: "tracks/1996/HUBERT.XM",
    size: "214,501 bytes",
    filters: { love: true },
    text: "i composed that track in tribute for hubert which teach me many things in music",
  },
  {
    filename: "tracks/1996/INFAME-TRAHISON.MOD",
    size: "355,622 bytes",
  },
  { filename: "tracks/1996/LASCAR.MOD", size: "377,730 bytes" },
  { filename: "tracks/1996/RABRA.MOD", size: "486,366 bytes" },
  {
    filename: "tracks/1996/ROOM.MOD",
    size: "230,296 bytes",
    filters: { love: true },
  },
  { filename: "tracks/1996/STYLE.MOD", size: "634,594 bytes" },
  { filename: "tracks/1996/Violon.xm", size: "616,753 bytes" },
  { filename: "tracks/1997/ACIDJA97.MOD", size: "218,240 bytes" },
  { filename: "tracks/1997/CAPTIV97.MOD", size: "130,440 bytes" },
  { filename: "tracks/1997/DECISI97.XM", size: "822,216 bytes" },
  { filename: "tracks/1997/FULL97.MOD", size: "147,026 bytes" },
  { filename: "tracks/1997/LEGACY.MOD", size: "577,936 bytes" },
  { filename: "tracks/1997/SACRIF.XM", size: "1.06MB" },
  { filename: "tracks/1997/WHITE.XM", size: "523,065 bytes" },
  { filename: "tracks/1997/violin.mod", size: "409,474 bytes" },
  { filename: "tracks/1998/ACT3.XM", size: "3.53MB" },
  { filename: "tracks/1998/ACT4.XM", size: "1,014,379 bytes" },
  { filename: "tracks/1998/COMA.XM", size: "1.79MB" },
  { filename: "tracks/1998/CONTRA.XM", size: "2.09MB" },
  { filename: "tracks/1998/FOUND.XM", size: "1.58MB" },
  { filename: "tracks/1998/GROOVE00.XM", size: "1.20MB" },
  { filename: "tracks/1998/IMPACT.XM", size: "1.47MB" },
  { filename: "tracks/1998/INSPECTE.XM", size: "2.75MB" },
  { filename: "tracks/1998/MAITRISE.XM", size: "1.57MB" },
  { filename: "tracks/1998/PARIS.XM", size: "1.27MB" },
  { filename: "tracks/1998/SOUL.XM", size: "713,813 bytes" },
  { filename: "tracks/1998/TOMB.XM", size: "2.03MB" },
  {
    filename: "tracks/1999/COOLMAX.XM",
    size: "4.2MB",
    filters: { love: true },
  },
  { filename: "tracks/1999/GROSSEF.XM", size: "225KB" },
  {
    filename: "tracks/1997/non-reglo97.mod",
    size: "311KB",
    text: "a track for my old music group hkm",
  },
  // { filename: "tracks/1996/travailler-med-v2.0.mod", size: "285KB" },
  // { filename: "tracks/1996/non-reglo-mix-funky.mod", size: "180KB" },
  {
    filename: "tracks/1999/RAISON.XM",
    size: "8.4MB",
    filters: { love: true },
    text: "i usually composed track with high quality samples      and then i compressed it by hand by resampled them and removed all the unused sample      this track is his original format",
  },
];

export default modules_med;
